.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: pulse infinite 2s ease-in;
  }
}

.App-header {
  background-color: #17181b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.searchWrapper {
  border: 0;
}

.optionContainer {
  color: #aaa;
  background: #111;
  font-size: small;
  border: 0;
}

.multiSelectContainer ul {
  border: 1px solid #777;
}


@keyframes pulse {
	0% {
		transform: scale(0.95);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.95);
	}
}