@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #111;
  color: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input, select {
  width: 100%;
  padding: .5rem;
  background: #000;
  color: #aaa;
  border-color: #222;
  margin: .25rem 0;
  font-size: small;
}